import { useContext, useEffect } from 'react';
import { Modal } from 'antd';
import { ModalContext, type ModalType } from '@/contexts';

export type UseModalType = ModalType;

export const useModal = () => {
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    return () => {
      Modal.destroyAll();
    };
  }, []);

  return modalContext;
};

import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';
import { Receipt } from '@onefish/icons-react';
import {
  COMPLETE_DO_RESOURCE_NAME,
  PAYMENT_PROCESS_RESOURCE_NAME,
  VENDOR_PAYMENT_RESOURCE_NAME,
} from '@/constants/resources';

export const vendorPaymentResource: ResourceProps[] = [
  {
    name: VENDOR_PAYMENT_RESOURCE_NAME,
    meta: {
      label: 'Pembayaran Vendor',
      icon: <Receipt size={16} weight="fill" />,
    },
  },
  {
    name: COMPLETE_DO_RESOURCE_NAME,
    list: `/${VENDOR_PAYMENT_RESOURCE_NAME}/${COMPLETE_DO_RESOURCE_NAME}`,
    meta: {
      label: 'DO Selesai',
      parent: VENDOR_PAYMENT_RESOURCE_NAME,
    },
  },
  {
    name: PAYMENT_PROCESS_RESOURCE_NAME,
    list: `/${VENDOR_PAYMENT_RESOURCE_NAME}/${PAYMENT_PROCESS_RESOURCE_NAME}`,
    create: `/${VENDOR_PAYMENT_RESOURCE_NAME}/${PAYMENT_PROCESS_RESOURCE_NAME}/create`,
    show: `/${VENDOR_PAYMENT_RESOURCE_NAME}/${PAYMENT_PROCESS_RESOURCE_NAME}/show/:id`,
    meta: {
      label: 'Proses Pembayaran',
      parent: VENDOR_PAYMENT_RESOURCE_NAME,
    },
  },
];

export const vendorPaymentRoutes: RouteObject = {
  path: VENDOR_PAYMENT_RESOURCE_NAME,
  children: [
    {
      path: COMPLETE_DO_RESOURCE_NAME,
      children: [
        {
          index: true,
          lazy: () => {
            return import('./complete-do/list');
          },
        },
      ],
    },
    {
      path: PAYMENT_PROCESS_RESOURCE_NAME,
      children: [
        {
          index: true,
          lazy: () => {
            return import('./payment-process/list');
          },
        },
        {
          path: 'create',
          lazy: () => {
            return import('./payment-process/create');
          },
        },
        {
          path: 'show/:id',
          lazy: () => {
            return import('./payment-process/show');
          },
        },
      ],
    },
  ],
};

/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src
 */
import { type CanParams, type CanReturnType } from '@refinedev/core';
import { AUTH_V2_DOMAIN, AUTH_SERVICE_URL } from '@/configs';
import { auth, CreateHttp } from '@/libs';

const Http = CreateHttp(AUTH_SERVICE_URL);

export const accessControlProvider = {
  async can({ resource, action }: CanParams): Promise<CanReturnType> {
    try {
      const isLoggedIn = auth.isSignedAsLogged();
      const accessToken = await auth.getAccessToken();

      if (!isLoggedIn || !accessToken) {
        throw new Error('Access token required');
      }

      const profile = await auth.getProfile(accessToken);
      const email = profile.data?.email;
      if (!email) {
        return {
          can: false,
          reason: 'Unauthorized',
        };
      }

      const resp = await Http.post('/rbac/enforce', {
        resource,
        action,
        domain: AUTH_V2_DOMAIN,
        user: email,
      });

      return { can: resp.data.data.eligible };
    } catch (error: unknown) {
      if (error instanceof Error) {
        return {
          can: false,
          reason: error.message,
        };
      }

      throw error;
    }
  },
};

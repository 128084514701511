import { Authenticated as RefineAuthenticated, CanAccess } from '@refinedev/core';
import {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
  CatchAllNavigate,
} from '@refinedev/react-router-v6';
import { ErrorComponent, ThemedLayoutV2 } from '@refinedev/antd';
import {
  RouterProvider,
  createBrowserRouter,
  Outlet,
  type RouteObject,
  Navigate,
} from 'react-router-dom';
import { Typography } from 'antd';
import { useFlagData } from './hooks';
import { ENABLE_MAINTENANCE_MODE_FLAGR_ID } from '@/configs';
import { Header, PageUnauthorized, Sider } from './components';
import { authRoutes } from './pages/auth';
import { aboutRoutes } from './pages/about';
import { pagesRoutes, pagesResources } from './pages';
import { getRedirectPath } from './libs/localStorage';
import { RefineProvider } from './contexts';

const { Text } = Typography;

const Root = () => {
  return (
    <RefineProvider resources={pagesResources}>
      <Outlet />
      <UnsavedChangesNotifier />
      <DocumentTitleHandler />
    </RefineProvider>
  );
};

type AuthenticatedProps = {
  children: React.ReactNode;
};

const Authenticated = ({ children }: AuthenticatedProps) => {
  return (
    <RefineAuthenticated key="refine-authenticated" fallback={<CatchAllNavigate to="/login" />}>
      <ThemedLayoutV2
        Header={() => {
          return <Header sticky />;
        }}
        Sider={(props) => {
          return <Sider {...props} fixed />;
        }}
      >
        {children}
      </ThemedLayoutV2>
    </RefineAuthenticated>
  );
};

const AuthenticatedLayout = () => {
  return (
    <Authenticated>
      <Outlet />
    </Authenticated>
  );
};

const ProtectedLayout = () => {
  const flagData = useFlagData(ENABLE_MAINTENANCE_MODE_FLAGR_ID);
  const isON = flagData?.variantKey === 'on';
  const attachment = flagData?.variantAttachment;

  const title =
    attachment !== undefined && 'title' in attachment
      ? attachment.title
      : 'Sedang dalam pemeliharaan sistem.';

  const description =
    attachment !== undefined && 'description' in attachment
      ? attachment.description
      : 'Jika ada keperluan hubungi #proddev-troops-logistic atau buat Request/Issue Report di #bugreport-opstream-support dahulu ya 😉.';

  if (isON) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '100vh',
        }}
      >
        <Text>{String(title)}</Text>
        <Text>{String(description)}</Text>
      </div>
    );
  }

  return (
    <Authenticated>
      <CanAccess fallback={<PageUnauthorized />}>
        <Outlet />
      </CanAccess>
    </Authenticated>
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate replace={true} to="/dashboard" />,
      },
      {
        element: <ProtectedLayout />,
        children: pagesRoutes,
      },
      {
        element: (
          <RefineAuthenticated key="refine-redirect" fallback={<Outlet />}>
            <Navigate replace={true} to={getRedirectPath() ?? '/dashboard'} />,
          </RefineAuthenticated>
        ),
        children: authRoutes,
      },
      {
        children: aboutRoutes,
      },
      {
        element: <AuthenticatedLayout />,
        children: [
          {
            path: '*',
            element: <ErrorComponent />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;

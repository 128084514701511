import { useGo } from '@refinedev/core';
import { Button, Result } from 'antd';

export const PageUnauthorized = () => {
  const go = useGo();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Maaf, Anda tidak berwenang mengakses halaman ini."
      extra={
        <Button
          type="primary"
          onClick={() => {
            go({ to: '/' });
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

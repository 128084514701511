import type { DataProvider } from '@refinedev/core';
import { CreateHttp } from '@/libs/http';
import { TRANSPORT_MANAGEMENT_SERVICE_URL_V2 } from '@/configs';
import { providerMethod } from './tmsServiceProvider';

const apiUrl = TRANSPORT_MANAGEMENT_SERVICE_URL_V2;
const httpClient = CreateHttp(apiUrl);

export const tmsNextServiceProviderName = 'tmsNextService';

export const tmsNextServiceProvider: DataProvider = providerMethod(httpClient);

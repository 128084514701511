import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';

import { dashboardResource, dashboardRoutes } from './dashboard';
import {
  transportationManagementResource,
  transportationManagementRoutes,
} from './transportation-management';
import { deliveryOrderResource, deliveryOrderRoutes } from './delivery-order';
import { vendorPaymentResource, vendorPaymentRoutes } from './vendor-payment';

export const pagesResources: ResourceProps[] = [
  ...dashboardResource,
  ...deliveryOrderResource,
  ...transportationManagementResource,
  ...vendorPaymentResource
];

export const pagesRoutes: RouteObject[] = [
  dashboardRoutes,
  deliveryOrderRoutes,
  transportationManagementRoutes,
  vendorPaymentRoutes
];

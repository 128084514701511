import { createContext } from 'react';
import { type FormInstance } from 'antd';

export type MultiStepFormInstance<T extends object> = {
  title?: React.ReactNode;
  form: FormInstance<T>;
  getPreviousFormValues: () => Partial<T>;
};

export type MultiStepFormContextProps<T extends object> = Partial<
  Omit<MultiStepFormInstance<T>, 'form'>
>;

export const MultiStepFormContext = createContext<MultiStepFormContextProps<object>>({});

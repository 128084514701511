import type { RouteObject } from 'react-router-dom';
import { Typography, Space } from 'antd';
import { APP_COMMIT, APP_VERSION } from '@/configs';

export const aboutRoutes: RouteObject[] = [
  {
    path: '/about',
    element: (
      <div
        style={{
          width: '100%',
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Space direction="vertical" align="center">
          <Typography.Text>{APP_VERSION}</Typography.Text>
          <Typography.Text>{APP_COMMIT}</Typography.Text>
        </Space>
      </div>
    ),
  },
];

import { ElementType, ComponentPropsWithoutRef } from 'react';

// this component made because icon from `@onefish/icons-react`
// returning <svg/> and somethimes the icon is not align center
// so to make the icon align center everytime use it instead'
// of makeing redundant style better to create wrapper for it
export const Icon = <T extends ElementType = 'svg'>(
  props: {
    as?: T;
  } & ComponentPropsWithoutRef<T>,
) => {
  const { as: Comp = 'svg', 'data-testid': dataTestId, ...restProps } = props;
  return (
    <span className="anticon" data-testid={dataTestId}>
      <Comp {...restProps}></Comp>
    </span>
  );
};

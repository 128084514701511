import '@refinedev/antd/dist/reset.css';
import './styles/colors.css';
import './styles/antd-reset.css';
import './styles/global.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { EfisheryProvider } from '@efishery/sdk-reactjs';
import { FlagProvider } from '@onefish/flag';
import { AntdProvider, ModalProvider } from './contexts';
import { AUTH_V2_CLIENT_ID, AUTH_V2_STAGE, FLAGGING_SERVICE_URL, ENABLE_GTM } from './configs';
import { sentry } from './libs/helpers/sentry';
import { initializeGtm } from './libs/helpers';

import App from './App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const authRedirect = `${window.location.origin}/redirect`;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('Asia/Jakarta');

sentry.initialize();

if (ENABLE_GTM) {
  initializeGtm();
}

root.render(
  <React.StrictMode>
    <EfisheryProvider
      clientId={AUTH_V2_CLIENT_ID}
      stage={AUTH_V2_STAGE}
      redirectUrl={authRedirect}
      failedRedirectUrl={authRedirect}
      enableCookieKeys={true}
    >
      <FlagProvider flagUrl={FLAGGING_SERVICE_URL}>
        <AntdProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </AntdProvider>
      </FlagProvider>
    </EfisheryProvider>
  </React.StrictMode>,
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { GTM_ID } from '@/configs';
import TagManager from 'react-gtm-module';

export const initializeGtm = () => {
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };
  return TagManager.initialize(tagManagerArgs);
};

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const trackEvent = (eventName: string, eventProperties: any) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...eventProperties,
    });
  }
};

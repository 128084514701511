/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src/main/src/dataProviders/authDataProvider.ts
 */
import { AuthProvider } from '@refinedev/core';
import { auth } from '@/libs/auth';
import { sentry } from '@/libs/helpers/sentry';

export const authProvider: AuthProvider = {
  login: async ({ grantType, code, redirectPath }) => {
    const { accessToken } = await auth.persistExchange(code, grantType);
    await auth.getProfile(accessToken);

    return {
      success: true,
      redirectTo: redirectPath ? redirectPath : '/',
    };
  },
  logout: async () => {
    await auth.logout();

    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const isLoggedIn = await auth.isSignedAsLogged();
    if (!isLoggedIn) {
      return {
        authenticated: false,
        redirectTo: '/login',
      };
    }

    return {
      authenticated: true,
    };
  },
  getIdentity: async () => {
    const accessToken = await auth.getAccessToken();
    if (!accessToken) {
      return undefined;
    }

    const profile = await auth.getProfile(accessToken);

    if (profile?.data) {
      sentry.setUser(profile?.data);
    }
    return profile.data;
  },
  onError: async (error) => {
    return { error };
  },
};

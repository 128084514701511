import { useCallback } from 'react';
import { useWarnAboutChange } from '@refinedev/core';
import { useModal } from './useModal';

export type WarnLeaveModalOptions = {
  onOk?: () => void;
  onCancel?: () => void;
  unsetWarnAfter?: boolean;
};

export type WarnLeaveModal = (options: WarnLeaveModalOptions) => void;

export const useWarnLeaveModal = () => {
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const { showModal } = useModal();

  const warnLeaveModal: WarnLeaveModal = useCallback(
    ({ onCancel, onOk }) => {
      showModal({
        title: 'Keluar dari halaman ini?',
        content: 'Apabila iya, semua informasi yang sudah dimasukan akan hilang.',
        okType: 'danger',
        okText: 'Keluar',
        cancelText: 'Batal',
        type: 'error',
        onOk,
        onCancel,
      });
    },
    [showModal],
  );

  const warnLeave: WarnLeaveModal = useCallback(
    ({ onCancel, onOk, unsetWarnAfter = true }) => {
      if (warnWhen) {
        warnLeaveModal({
          onOk: () => {
            onOk?.();
            if (unsetWarnAfter) {
              setWarnWhen(false);
            }
          },
          onCancel,
        });
      } else {
        onOk?.();
      }
    },
    [warnLeaveModal, setWarnWhen, warnWhen],
  );

  return { warnLeave, warnLeaveModal, warnWhen, setWarnWhen };
};

/**
 * - https://stackoverflow.com/a/48161723/12976234
 * - https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 */
export const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => {
      return b.toString(16).padStart(2, '0');
    })
    .join('');

  return hashHex;
};

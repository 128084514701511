import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';
import { Gauge } from '@onefish/icons-react';

export const dashboardResource: ResourceProps[] = [
  {
    name: 'dashboard',
    list: '/dashboard',
    meta: {
      canDelete: true,
      label: 'Dashboard',
      icon: <Gauge size={16} />,
    },
  },
];

export const dashboardRoutes: RouteObject = {
  path: 'dashboard',
  children: [
    {
      index: true,
      lazy: () => {
        return import('./list');
      },
    },
  ],
};

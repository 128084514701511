/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src
 */
import axios, { type AxiosInstance } from 'axios';
import { HttpError } from '@refinedev/core';
import { auth } from '../auth';
import { APP_NAME } from '@/configs';
import { sentry } from '../helpers/sentry';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any 
 -- Let it be */
const errorWrapper = (error: any) => {
  const errorMainMessage = error?.response?.data?.error ?? '';
  const errorDataMessage = error?.response?.data?.message ?? '';

  // Tests whether the string has a pattern of square brackets at the start and end or like a array
  const isArrayLike = /^\[.*\]$/.test(errorDataMessage);

  // Spesification detail error message
  const errorDetailMessage = isArrayLike
    ? errorDataMessage.replace(/[[\]"]/g, '').replace(/precondition /g, '')
    : error?.response?.data?.message;

  const messageError =
    errorMainMessage && errorDetailMessage
      ? `${errorMainMessage} - ${errorDetailMessage}`
      : errorMainMessage ?? errorDetailMessage ?? 'Something went wrong';

  const customError: HttpError = {
    message: messageError,
    statusCode: error?.response?.status ?? 500,
  };

  sentry.reportError(error, customError);

  return Promise.reject(customError);
};

const addRequestInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      if (config.headers !== undefined) {
        config.headers['X-Client-Id'] = APP_NAME;
        config.headers['Authorization'] = `Bearer ${await auth.getAccessToken()}`;
      }

      const { url, method, data, headers } = config;
      const { Authorization: _, ...restHeader } = headers;
      const request = { url, method, data, headers: restHeader };
      sentry.setContext('request', request);

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

const addResponseInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err?.config;

      if (!originalConfig?.sent && err?.response?.status === 401) {
        originalConfig.sent = true;

        try {
          const { accessToken } = await auth.persistRefresh();
          originalConfig.headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          // do whatever you wanna do with new accessToken here

          return await axiosInstance(originalConfig);
        } catch (err) {
          await auth.logout();
          return errorWrapper(err);
        }
      }

      return errorWrapper(err);
    },
  );
};

export const CreateHttp = (baseURL: string) => {
  const http = axios.create({
    baseURL,
  });

  addRequestInterceptor(http);
  addResponseInterceptor(http);

  return http;
};

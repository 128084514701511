/**
 * This file is generated (ejected from Refine)
 * by using command `swizzle` (npm run refine swizzle)
 *
 * Read more
 * https://refine.dev/docs/3.xx.xx/packages/documentation/cli/#swizzle
 */
import type { DataProvider } from '@refinedev/core';
import { CreateHttp } from '@/libs/http';
import { TRANSPORT_MANAGEMENT_SERVICE_URL } from '@/configs';
import { AxiosInstance } from 'axios';

const apiUrl = TRANSPORT_MANAGEMENT_SERVICE_URL;
const httpClient = CreateHttp(apiUrl);

/* v8 ignore start */
export const providerMethod = (httpClient: AxiosInstance) => {
  const provider: DataProvider = {
    getList: async ({ resource, filters, pagination }) => {
      const params: Record<string, string | number> = {};

      // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
      const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
      if (mode === 'server') {
        params.page = current;
        params.limit = pageSize;
      }

      filters?.forEach((filter) => {
        if ('field' in filter) {
          params[filter['field']] = filter.value;
        }
      });

      return await httpClient.get(resource, { params }).then((r) => {
        return {
          data: r.data.data,
          total: r.data?.meta?.total_data | 0,
        };
      });
    },
    create: async ({ resource, variables }) => {
      return await httpClient.post(resource, variables).then((r) => {
        return r.data;
      });
    },
    update: async ({ resource, id, variables }) => {
      try {
        return await httpClient.put(`${resource}/${id}`, variables).then((r) => {
          return r.data;
        });
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
        This `any` is intended to receipt any type
      */
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id }) => {
      try {
        return await httpClient.get(`${resource}/${id}`).then((r) => {
          return r.data;
        });
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
        This `any` is intended to receipt any type
      */
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    deleteOne: async ({ resource, id }) => {
      return await httpClient.delete(`${resource}/${id}`).then((r) => {
        return r.data;
      });
    },
    custom: async ({ url, method, payload, query, headers, meta }) => {
      let response;

      switch (method) {
        case 'put':
        case 'post':
        case 'patch':
          response = await httpClient[method](url, payload, { headers });
          break;
        case 'delete':
          response = await httpClient.delete(url, { headers });
          break;
        default:
          response = await httpClient.get(url, {
            headers,
            params: query,
            responseType: meta?.responseType,
          });
          break;
      }

      return Promise.resolve(response.data);
    },
    getApiUrl: () => {
      return apiUrl;
    },
  };
  return provider;
};
/* v8 ignore end */

export const tmsServiceProvider: DataProvider = providerMethod(httpClient);

import type { UploadFile } from '@/types';
import type { UploadProps } from 'antd';

import { useCallback } from 'react';
import {
  FILE_MANAGER_SERVICE_BUCKET,
  FILE_MANAGER_SERVICE_URL,
  ENABLE_FFMS_EXPLICIT_BUCKET,
} from '@/configs';
import { CreateHttp, sha256, currentDateInUTC } from '@/libs';

export type CustomRequestOption = Parameters<NonNullable<UploadProps['customRequest']>>[0];

export type CustomRequestFn = (option: CustomRequestOption) => Promise<void>;

const Http = CreateHttp(FILE_MANAGER_SERVICE_URL);

// access key for upload file to FMS need generated every each
// request, it's suggested by the service author
const generateAccessKey = () => {
  // Example: 2023/02/07 04:14
  const now = currentDateInUTC();
  return sha256(`efishery-${now}`);
};

const generateRandomString = () => {
  return (Math.random() + 1).toString(36).substring(7) + Date.now().toString();
};

// FMS service don't add unique idenfier to file, so we need to
// add it by ourself.
//
// if we don't add unique idenfier the same file name will replaced
// for example if we upload `foo.jpg` that show dog image, and we
// upload other `foo.jpg` that show cat iamge, the previous
// `foo.jpg` that show dog image, become showing cat image
const addRandomPrefixToFileName = (originalFileName: string) => {
  return `${generateRandomString()}-${originalFileName}`;
};

export type FileValidation = (file: File) => boolean;

export type UseAntdCustomRequestUploadParams = {
  validate?: FileValidation;
};

export const useAntdCustomRequestUpload = ({ validate }: UseAntdCustomRequestUploadParams = {}) => {
  const customRequest = useCallback<CustomRequestFn>(
    async (option) => {
      // The type `file` from Antd `UploadRequestOption` possibly have `RcFile` | `Blob` | `string`
      // data type, don't know how it could possible `string`, but if the file type is `string`
      // than we should block it because FMS can't accept string
      if (option.file === undefined || typeof option.file === 'string') {
        return option.onError?.({ name: 'Upload Error', message: 'Something wrong with file' });
      }

      // The type `file` from Antd `UploadRequestOption` at this point possibly have `RcFile` | `Blob`
      // `RcFile` is just extends from `File`.
      //
      // If type type of `file` is `Blob` then there is no `lastModified` & `name`
      // so we set falsy value. And we need to convert it to `File` type
      const lastModified = 'lastModified' in option.file ? option.file.lastModified : 0;
      const fileName = 'name' in option.file ? option.file.name : '';
      const uploadFileCandidate: File =
        option.file instanceof Blob
          ? new File([option.file], fileName, {
              type: option.file.type,
              lastModified: lastModified,
            })
          : option.file;

      //   If any validation function then run, and if fail then return fail
      if (validate !== undefined && !validate?.(uploadFileCandidate)) {
        return option.onError?.({ name: 'Upload Error', message: 'Fail to validate file' });
      }

      const formData = new FormData();
      formData.append('files', uploadFileCandidate);
      formData.append('name', addRandomPrefixToFileName(fileName.replaceAll(' ', '')));
      if (ENABLE_FFMS_EXPLICIT_BUCKET) {
        formData.append('bucket', FILE_MANAGER_SERVICE_BUCKET);
      }

      // If in test mode (run test) we don't want
      // to call real API instead just directly
      // send success to end the process
      if (import.meta.env.MODE === 'test') {
        return option.onSuccess?.(fileName);
      }

      // Why we call HTTP funciton directly, not using through Refine adapter?
      //  1. Keep simple, we don't need Refine adapter to do that
      //  2. We need to wathc the upload progress so can show progress bar
      return Http.post('upload', formData, {
        headers: {
          'X-Access-Key': await generateAccessKey(),
        },
        onUploadProgress: ({ total, loaded }) => {
          option.onProgress?.({
            percent: Number(Math.round((loaded / (total ?? 0)) * 100).toFixed(2)),
          });
        },
      })
        .then((resp) => {
          const res: UploadFile = resp.data.data?.[0];
          if (res === undefined) {
            return;
          }

          option.onSuccess?.(res.location);
        })
        .catch((error) => {
          option.onError?.({ name: 'Upload Error', message: error.message });
        });
    },
    [validate],
  );

  return { customRequest };
};

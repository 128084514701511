import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useWarnLeaveModal, type WarnLeaveModal } from './useWarnLeaveModal';

export const useRefineWarnModal = () => {
  const { warnLeave, warnWhen } = useWarnLeaveModal();
  const blocker = useBlocker(warnWhen);

  const warnOnCallback = useCallback<WarnLeaveModal>(
    (params) => {
      warnLeave(params);
    },
    [warnLeave],
  );

  // Copy and modify from:
  // https://github.com/louis-young/react-router/blob/9c14068569d2e53ac233611b8c631642199d550a/packages/react-router-dom/index.tsx
  //
  // Read more:
  // https://github.com/remix-run/react-router/discussions/10489
  // https://github.com/remix-run/react-router/pull/10687
  // https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx#L1460
  useEffect(() => {
    if (blocker.state === 'blocked') {
      warnOnCallback({
        onOk: () => {
          blocker.proceed();
        },
        onCancel: () => {
          blocker.reset();
        },
      });
    }
  }, [blocker, warnOnCallback]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !warnWhen) {
      blocker.reset();
    }
  }, [blocker, warnWhen]);

  return { warnOnCallback };
};

import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';
import { Note } from '@onefish/icons-react';
import {
  ORDER_RESOURCE_NAME,
  DELIVERY_ORDER_RESOURCE_NAME,
  SHIPMENT_PROCESS_RESOURCE_NAME,
} from '@/constants/resources';

export const deliveryOrderResource: ResourceProps[] = [
  {
    name: DELIVERY_ORDER_RESOURCE_NAME,
    meta: {
      label: 'Pengiriman Order',
      icon: <Note size={16} weight="fill" />,
    },
  },
  {
    name: ORDER_RESOURCE_NAME,
    list: `/${DELIVERY_ORDER_RESOURCE_NAME}/${ORDER_RESOURCE_NAME}`,
    show: `/${DELIVERY_ORDER_RESOURCE_NAME}/${ORDER_RESOURCE_NAME}/show/:id`,
    meta: {
      label: 'Order',
      parent: DELIVERY_ORDER_RESOURCE_NAME,
    },
  },
  {
    name: SHIPMENT_PROCESS_RESOURCE_NAME,
    list: `/${DELIVERY_ORDER_RESOURCE_NAME}/${SHIPMENT_PROCESS_RESOURCE_NAME}`,
    create: `/${DELIVERY_ORDER_RESOURCE_NAME}/${SHIPMENT_PROCESS_RESOURCE_NAME}/create`,
    edit: `/${DELIVERY_ORDER_RESOURCE_NAME}/${SHIPMENT_PROCESS_RESOURCE_NAME}/edit/:id`,
    show: `/${DELIVERY_ORDER_RESOURCE_NAME}/${SHIPMENT_PROCESS_RESOURCE_NAME}/show/:id`,
    meta: {
      label: 'Proses Pengiriman ',
      parent: DELIVERY_ORDER_RESOURCE_NAME,
    },
  },
];

export const deliveryOrderRoutes: RouteObject = {
  path: DELIVERY_ORDER_RESOURCE_NAME,
  children: [
    {
      path: ORDER_RESOURCE_NAME,
      children: [
        {
          index: true,
          lazy: () => {
            return import('./order/list');
          },
        },
        {
          path: 'show/:id',
          lazy: () => {
            return import('./order/show');
          },
        },
      ],
    },
    {
      path: SHIPMENT_PROCESS_RESOURCE_NAME,
      children: [
        {
          index: true,
          lazy: () => {
            return import('./shipment-process/list');
          },
        },
        {
          path: 'create',
          lazy: () => {
            return import('./shipment-process/create');
          },
        },
        {
          path: 'edit/:id',
          lazy: () => {
            return import('./shipment-process/edit');
          },
        },
        {
          path: 'show/:id',
          lazy: () => {
            return import('./shipment-process/show');
          },
        },
      ],
    },
  ],
};

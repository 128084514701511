import type { RefineThemedLayoutV2HeaderProps } from '@refinedev/antd';
import type { UserProfile } from '@efishery/sdk-core';
import { useGetIdentity } from '@refinedev/core';
import { Layout as AntdLayout, Avatar, Space, Typography, theme } from 'antd';
import React from 'react';
import randomAvatar from '@/images/random-avatar.svg';

const { Text } = Typography;
const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky }) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<UserProfile>();

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 24px',
    height: '64px',
    borderBottom: '1px solid var(--color-black-100)',
  };

  if (sticky) {
    headerStyles.position = 'sticky';
    headerStyles.top = 0;
    headerStyles.zIndex = 1000;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        <Space style={{ marginLeft: '8px' }} size="middle">
          {user?.full_name && <Text strong>{user.full_name}</Text>}
          <Avatar src={randomAvatar} />
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};

import type { ResourceProps } from '@refinedev/core';
import type { RouteObject } from 'react-router-dom';
import { Truck } from '@onefish/icons-react';
import {
  VENDOR_LOGISTIC_RESOURCE_NAME,
  VENDOR_CONTRACT_RESOURCE_NAME,
  DRIVER_INTERNAL_RESOURCE_NAME,
} from '@/constants/resources';

const transportationManagementResourceName = 'transportation-management';

export const transportationManagementResource: ResourceProps[] = [
  {
    name: transportationManagementResourceName,
    meta: {
      canDelete: true,
      label: 'Manajemen Transportasi',
      icon: <Truck size={16} weight="fill" />,
    },
  },
  {
    name: VENDOR_LOGISTIC_RESOURCE_NAME,
    list: `/${transportationManagementResourceName}/${VENDOR_LOGISTIC_RESOURCE_NAME}`,
    create: `/${transportationManagementResourceName}/${VENDOR_LOGISTIC_RESOURCE_NAME}/create`,
    show: `/${transportationManagementResourceName}/${VENDOR_LOGISTIC_RESOURCE_NAME}/show/:id`,
    meta: {
      label: 'Vendor Logistik',
      parent: transportationManagementResourceName,
    },
  },
  {
    name: VENDOR_CONTRACT_RESOURCE_NAME,
    create: `/${transportationManagementResourceName}/${VENDOR_LOGISTIC_RESOURCE_NAME}/:logisticVendorId/contract/create`,
    edit: `/${transportationManagementResourceName}/${VENDOR_LOGISTIC_RESOURCE_NAME}/:logisticVendorId/contract/create-addendum/:id`,
    meta: {
      label: 'Perjanjian Kerja Sama',
      parent: VENDOR_LOGISTIC_RESOURCE_NAME,
      hide: true,
    },
  },
  {
    name: DRIVER_INTERNAL_RESOURCE_NAME,
    list: `/${transportationManagementResourceName}/${DRIVER_INTERNAL_RESOURCE_NAME}`,
    create: `/${transportationManagementResourceName}/${DRIVER_INTERNAL_RESOURCE_NAME}/create`,
    edit: `/${transportationManagementResourceName}/${DRIVER_INTERNAL_RESOURCE_NAME}/edit/:id`,
    meta: {
      label: 'Driver Internal',
      parent: transportationManagementResourceName,
    },
  },
];

export const transportationManagementRoutes: RouteObject = {
  path: transportationManagementResourceName,
  children: [
    {
      path: VENDOR_LOGISTIC_RESOURCE_NAME,
      children: [
        {
          index: true,
          lazy: () => {
            return import('./vendor-logistic/list');
          },
        },
        {
          path: 'create',
          lazy: () => {
            return import('./vendor-logistic/create');
          },
        },
        {
          path: 'show/:id',
          lazy: () => {
            return import('./vendor-logistic/show');
          },
        },
        {
          path: ':transporterId/contract/create',
          lazy: () => {
            return import('./vendor-contract/create');
          },
        },
        {
          path: ':transporterId/contract/create-addendum/:id',
          lazy: () => {
            return import('./vendor-contract/create-addendum');
          },
        },
      ],
    },
    {
      path: DRIVER_INTERNAL_RESOURCE_NAME,
      children: [
        {
          index: true,
          lazy: () => {
            return import('./driver-internal/list');
          },
        },
        {
          path: 'create',
          lazy: () => {
            return import('./driver-internal/create');
          },
        },
        {
          path: 'edit/:id',
          lazy: () => {
            return import('./driver-internal/edit');
          },
        },
      ],
    },
  ],
};

import { type PropsWithChildren, useCallback, createContext, useMemo } from 'react';
import { Modal, type ModalFuncProps, type ButtonProps, App } from 'antd';
import { ModalFunc } from 'antd/es/modal/confirm';
import { NotificationWarningIcon } from '@/components';

const primaryNotificationWarningIcon = <NotificationWarningIcon color="var(--color-blue-500)" />;
const primaryOkButtonProps: ButtonProps = {
  className: 'button-brand-primary',
};
const primaryCancelButtonProps: ButtonProps = {
  className: 'button-brand-secondary',
};

const warningNotificationWarningIcon = <NotificationWarningIcon />;
const warningOkButtonProps: ButtonProps = {
  className: 'button-caution-secondary',
};
const warningCancelButtonProps: ButtonProps = {
  className: 'button-caution-tertiary',
};

const dangerOkButtonProps: ButtonProps = {
  className: 'button-danger-secondary',
};

export type ModalType = NonNullable<ModalFuncProps['type']>;

const modalVariant: Record<ModalType, ModalFuncProps> = {
  confirm: {
    icon: primaryNotificationWarningIcon,
    okButtonProps: primaryOkButtonProps,
    cancelButtonProps: primaryCancelButtonProps,
  },
  warning: {
    icon: warningNotificationWarningIcon,
    okButtonProps: warningOkButtonProps,
    cancelButtonProps: warningCancelButtonProps,
  },
  error: {
    icon: warningNotificationWarningIcon,
    okButtonProps: dangerOkButtonProps,
  },
  info: {},
  success: {},
  warn: {},
};

export type ModalContextType = {
  showModal: ModalFunc;
  getModalVariant: (type: ModalType) => ModalFuncProps;
};

export const ModalContext = createContext<ModalContextType>({
  showModal: Modal.confirm,
  getModalVariant: () => {
    return {};
  },
});

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const { modal } = App.useApp();

  const getModalVariant = useCallback((type: ModalType) => {
    return modalVariant[type];
  }, []);

  const showModal = useCallback(
    (props: ModalFuncProps) => {
      const showModalVariant = getModalVariant(props.type ?? 'confirm');

      return modal.confirm({
        centered: true,
        icon: <NotificationWarningIcon />,
        ...props,
        ...showModalVariant,
        okButtonProps: {
          ...showModalVariant.okButtonProps,
          ...props.okButtonProps,
        },
        cancelButtonProps: {
          ...showModalVariant.cancelButtonProps,
          ...props.cancelButtonProps,
        },
      });
    },
    [modal, getModalVariant],
  );

  const contextValue = useMemo(() => {
    return { showModal, getModalVariant };
  }, [showModal, getModalVariant]);

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

/**
 * This file is copied from `core-internal-tools`
 * https://bitbucket.org/efishery/internal-tools/src/main/src/dataProviders/supportDataProvider.ts
 */

import { DataProvider } from '@refinedev/core';
import { CreateHttp } from '@/libs/http';
import { CORE_SUPPORT_SERVICE_URL } from '@/configs';

const Http = CreateHttp(CORE_SUPPORT_SERVICE_URL);

export const coreServiceProviderName = 'coreSupportService';

export const coreServiceDataProvider: DataProvider = {
  getList: async ({ resource, filters, pagination }) => {
    const params: Record<string, string | number> = {};

    // https://refine.dev/docs/api-reference/antd/hooks/table/useTable/#paginationmode
    // https://bitbucket.org/efishery/internal-tools/src/main/src/dataProviders/supportDataProvider.ts
    const { current = 1, pageSize = 10, mode = 'off' } = pagination ?? {};
    if (mode === 'server') {
      params.page = current;
      params.limit = pageSize;
    }

    filters?.forEach((filter) => {
      if ('field' in filter) {
        params[filter['field']] = filter.value;
      }
    });
    return await Http.get(resource, { params }).then((r) => {
      return {
        data: r.data.data,
        total: r.data?.metadata?.total_records | 0,
      };
    });
  },
  create: async ({ resource, variables }) => {
    return await Http.post(resource, variables).then((r) => {
      return r.data;
    });
  },
  update: async ({ resource, id, variables }) => {
    return await Http.put(`${resource}/${id}`, variables).then((r) => {
      return r.data;
    });
  },
  getOne: async ({ resource, id }) => {
    return await Http.get(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  deleteOne: async ({ resource, id }) => {
    return await Http.delete(`${resource}/${id}`).then((r) => {
      return r.data;
    });
  },
  getApiUrl: () => {
    return CORE_SUPPORT_SERVICE_URL;
  },
};

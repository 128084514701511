import React from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { APP_ENVIRONMENT, SENTRY_DSN, APP_VERSION, ENABLE_SENTRY } from '@/configs';
import type { UserProfile } from '@efishery/sdk-core';

const initialize = () => {
  if (!ENABLE_SENTRY) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    sampleRate: 1,
    release: APP_VERSION,
    environment: APP_ENVIRONMENT,
  });
};

const setUser = (user: UserProfile) => {
  Sentry.setUser({
    email: user?.email ?? '',
    username: user?.full_name ? user?.full_name.replace(/\s+/g, '_') : '',
  });
};

/*  eslint-disable-next-line @typescript-eslint/no-explicit-any --
		this `any` expected because there are different data types and 
    there is data that must be transformed first
		*/
const setContext = (context: any, content: any) => {
  Sentry.setContext(context, content);
};

/*  eslint-disable-next-line @typescript-eslint/no-explicit-any --
		this `any` expected because there are different data types and 
    there is data that must be transformed first
		*/
export const reportError = (useCase: any, content: any) => {
  Sentry.setContext('manual-error-reporting', typeof content === 'object' ? content : { content });
  Sentry.captureException(useCase instanceof Error ? useCase : new Error(useCase));
};

export const sentry = {
  initialize,
  setUser,
  setContext,
  reportError,
};

/**
 * This file is copy-paste from Refine source
 * https://github.com/refinedev/refine/blob/next/packages/antd/src/components/undoableNotification/index.tsx
 *
 * Used in custom `notificationProvider` that override
 * default Refine `notificationProvider`
 */
import React from 'react';
import { Button, notification, Progress } from 'antd';
import { OpenNotificationParams } from '@refinedev/core';
import { ArrowCounterClockwise } from '@onefish/icons-react';
import { Icon } from '@/components';

export type UndoableNotificationProps = {
  notificationKey: OpenNotificationParams['key'];
  message: OpenNotificationParams['message'];
  cancelMutation: OpenNotificationParams['cancelMutation'];
  undoableTimeout: OpenNotificationParams['undoableTimeout'];
};

export const UndoableNotification: React.FC<UndoableNotificationProps> = ({
  notificationKey,
  message,
  cancelMutation,
  undoableTimeout,
}) => {
  const [notify] = notification.useNotification();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '-7px',
      }}
    >
      <Progress
        type="circle"
        percent={(undoableTimeout ?? 0) * 20}
        format={(time) => {
          return time && time / 20;
        }}
        width={50}
        strokeColor="#1890ff"
        status="normal"
      />
      <span style={{ marginLeft: 8, width: '100%' }}>{message}</span>
      <Button
        style={{ flexShrink: 0 }}
        onClick={() => {
          cancelMutation?.();
          notify.destroy(notificationKey ?? '');
        }}
        disabled={undoableTimeout === 0}
        icon={<Icon as={ArrowCounterClockwise} />}
      ></Button>
    </div>
  );
};

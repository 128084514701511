import dayjs, { Dayjs } from 'dayjs';

export type DateRange = [Dayjs, Dayjs] | undefined;

/**
 * This function is copied from `octo-fish-internal-tools`
 * https://bitbucket.org/efishery/fish-internal-tool/src/master/frontend/src/libs/utils/*
 */
export const formatDateRange = (startDate: string, endDate: string) => {
  const format = 'D MMM YYYY';
  const dateStart = dayjs.utc(startDate.trim()).local();
  const dateEnd = dayjs.utc(endDate.trim()).local();

  if (dateStart.year() !== dateEnd.year()) {
    return `${dateStart.format(format)} - ${dateEnd.format(format)}`;
  }

  if (dateStart.month() !== dateEnd.month()) {
    return `${dateStart.format('D MMM')} - ${dateEnd.format(format)}`;
  }

  if (dateStart.day() !== dateEnd.day()) {
    return `${dateStart.format('D')} - ${dateEnd.format(format)}`;
  }

  return `${dateStart.format(format)}`;
};

export const formatDateRangeDefault = (date: DateRange) => {
  const format = 'D MMM YYYY';
  let startDate = '';
  let endDate = '';

  if (date && date.length > 0) {
    startDate = dayjs(date[0]).format(format);
    endDate = dayjs(date[1]).format(format);
  }
  return `${startDate} - ${endDate}`;
};

export const getStartEndDate = (date: DateRange) => {
  const format = 'D MMMM YYYY';
  let startDate = '';
  let endDate = '';

  if (date && date.length > 0) {
    startDate = dayjs(date[0]).format(format);
    endDate = dayjs(date[1]).format(format);
  }
  return { startDate, endDate };
};

/**
 * This function is copied from `octo-fish-internal-tools`
 * https://bitbucket.org/efishery/fish-internal-tool/src/master/frontend/src/libs/utils/*
 */
export const formatTimeRange = (startDate: string, endDate: string) => {
  const format = 'HH:mm';
  const dateStart = dayjs.utc(startDate.trim()).local();
  const dateEnd = dayjs.utc(endDate.trim()).local();

  const timeStartEnd = `${dateStart.format(format)} - ${dateEnd.format(format)}`;

  if (dateStart.isSame(dateEnd, 'hour') && dateStart.isSame(dateEnd, 'minute')) {
    return dateStart.format(format);
  }
  return timeStartEnd;
};

export const formatTimeDefault = (date: string) => {
  return dayjs(date).format('HH : mm');
};

export const formatDateDefault = (date: string | Dayjs) => {
  return dayjs(date).format('D MMMM YYYY');
};

export const formatDateShort = (date: string | Dayjs) => {
  return dayjs(date).format('D MMM YYYY');
};

export const formatDateFull = (date: string | Dayjs) => {
  return dayjs(date).format('D MMMM YYYY, h:mm A');
};

/**
 * Format date to `DD//MM/YYYY` format
 * like `12/20/2012`
 */
export const formatCurrentDate = (date: string | Dayjs) => {
  return dayjs(date).format('DD/MM/YYYY');
};

/**
 * Format `dayjs` object to `YYYY-MM-DD` format
 * like `2012-12-20`
 */
export const dayjsToISO8601Date = (date: Dayjs) => {
  return date.format('YYYY-MM-DD');
};

/**
 * Format `dayjs` object to `HH:mm:ssZ` format
 * like `23:59:59-05:00`
 */
export const dayjsToISO8601Time = (date: Dayjs) => {
  return date.format('HH:mm:ssZ');
};

/**
 * For disable date before today in form field
 */
export const disabledPreviousDate = (current: Dayjs) => {
  return current && current < dayjs().add(-1, 'day').endOf('day');
};

/**
 * For disable date before min date in form field
 */
export const disabledFromMinDate = (current: Dayjs, minDate: Dayjs) => {
  return current && minDate && current.isBefore(minDate.startOf('day'));
};

/**
 * For disable date after max date in form field
 */
export const disabledFromMaxDate = (current: Dayjs, maxDate: Dayjs) => {
  return current && maxDate && current.isAfter(maxDate.startOf('day'));
};

/**
 * Return in format YYYY/MM/DD HH:mm
 */
export const currentDateInUTC = () => {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
  const date = String(currentDate.getUTCDate()).padStart(2, '0');
  const hour = String(currentDate.getUTCHours()).padStart(2, '0');
  const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');

  return `${year}/${month}/${date} ${hour}:${minute}`;
};

// Pengiriman Order Menu
export const DELIVERY_ORDER_RESOURCE_NAME = 'delivery-order';
export const SHIPMENT_PROCESS_RESOURCE_NAME = 'shipment-process';
export const ORDER_RESOURCE_NAME = 'order';
export const SHIPPING_ORDER = 'shipping-order';

// Manajemen Transportasi Menu
export const VENDOR_LOGISTIC_RESOURCE_NAME = 'logistic-vendor';
export const VENDOR_CONTRACT_RESOURCE_NAME = 'logistic-vendor-contract';
export const DRIVER_INTERNAL_RESOURCE_NAME = 'driver-internal';

// Pembayaran Vendor Menu
export const VENDOR_PAYMENT_RESOURCE_NAME = 'vendor-payment';
export const COMPLETE_DO_RESOURCE_NAME = 'complete-do';
export const PAYMENT_PROCESS_RESOURCE_NAME = 'payment-process';

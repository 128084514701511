import { ConfigProvider, App } from 'antd';
import colors from '../styles/colors';

type AntdProviderProps = {
  children: React.ReactNode;
};

export const AntdProvider = ({ children }: AntdProviderProps) => {
  return (
    <ConfigProvider
      form={{
        scrollToFirstError: {
          // You can read about `boundary` here
          // https://github.com/scroll-into-view/compute-scroll-into-view#boundary
          //
          // this function will called several time by <Form/> element
          // until the `parent` is <body/> if it return `true`,
          //
          // the first call `parent` value is the field that return error, so
          // we just want to scroll to that element, and don't need reach to
          // body
          boundary: (parent) => {
            // if parent element not visible in document
            // then continue to traverse the boundary until
            // the nearest visible parent element
            let shouldContinue = false;
            if ((parent?.getClientRects?.()?.length || 0) === 0) {
              shouldContinue = true;
            } else {
              parent?.scrollIntoView?.({ block: 'center' });
            }
            return shouldContinue;
          },
        },
      }}
      theme={{
        token: {
          fontSize: 14,
          fontFamily: 'Inter, sans-serif',
          colorPrimary: colors['--color-observatory-500'],
          colorLink: colors['--color-observatory-500'],
          colorText: colors['--color-black-400'],
        },
      }}
    >
      <App>{children}</App>
    </ConfigProvider>
  );
};
